import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';


export const notifyError = (msg) => {
	NotificationManager.error(msg, 'Error', 5000);
};
export const notifySuccess = (msg) => {
	NotificationManager.success(msg, 'Success', 5000);
};

