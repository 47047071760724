import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

const SortableColumn = (props) => {

	const [sortField, sortDirection] = props.sort ? props.sort.split('.') : [ '', '' ];
	const handleOnClick = (currDir) => {
		if (currDir == '' || currDir == 'desc') {
			props.onChange(props.name + '.asc');
		}
		if (currDir == 'asc') {
			props.onChange(props.name + '.desc');
		}
	};

	return (		
		<div onClick={() => handleOnClick(sortDirection)}>
			{(sortDirection == 'asc' && sortField == props.name) ? (<FontAwesomeIcon icon={faSortUp} fixedWidth />) : ''}
			{(sortDirection == 'desc' && sortField == props.name) ? (<FontAwesomeIcon icon={faSortDown} fixedWidth />) : ''}
			{props.children}
		</div>
	);

};

export default SortableColumn;

