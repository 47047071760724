import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {notifyError, notifySuccess} from './notifications';
import SortableColumn from './SortableColumn';
import './css/BotList.css';
import config from '../config/config';

const BotList = () => {
  const searchFormRef = React.createRef();
  const [bots, setBots] = useState([]);
  const [selectedBots, setSelectedBots] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [botsPerPage, setBotsPerPage] = useState(50);
  const [searchDirection, setSearchDirection] = useState('all');
  const [sortOrder, setSortOrder] = useState('');
  const [activatedBots, setActivatedBots] = useState([]);
  const [stateSummary, setStateSummary] = useState([]);
  const [webhookUrls, setWebhookUrls] = useState({
    start: '',
    stop: '',
    delete: '',
    convert: '',
  });

  const fetchBots = useCallback(() => {
    console.log(searchFormRef);
    const formData = new FormData(searchFormRef.current);
    const searchParams = new URLSearchParams();

    formData.forEach((value, key) => {
      if (value) {
        searchParams.append(key, value);
      }
    });

    if (sortOrder) {
      searchParams.append('sort', sortOrder);
    }

    fetch(`${config.apiBaseUrl}/api/bot?${searchParams.toString()}`)
      .then(response => response.json())
      .then(data => {
        setBots(Array.isArray(data.bots) ? data.bots : []);
        setStateSummary(data.stateSummary.map((e) => {
          if (e.state == 'all') return {...e, caption: `All (${e.count})` };
          if (e.state == 'started') return {...e, caption: `Started (${e.count})`};
          if (e.state == 'stopped') return {...e, caption: `Stopped (${e.count})`};
          if (e.state == 'working') return {...e, caption: `Working (${e.count})`};
          if (e.state == 'deleted') return {...e, caption: `Deleted (${e.count})`};
          return {...e, caption:`${e.state} (${e.count})`};
        }));       
        setCurrentPage(1);
      })
      .catch(error => console.error('Error loading bots:', error));
  }, [searchFormRef, sortOrder]);


  const generateWebhookUrls = useCallback(() => {
    const baseUrl = `${config.apiBaseUrl}/api/bot/massaction`;
    const ids = btoa(selectedBots.join(','));
    setWebhookUrls({
      start: `${config.apiBaseUrl}/api/botmassaction/start/${ids}`,
      stop: `${config.apiBaseUrl}/api/botmassaction/stop/${ids}`,
      delete: `${config.apiBaseUrl}/api/botmassaction/delete/${ids}`,
      convert: `${config.apiBaseUrl}/api/botmassaction/convert/${ids}`,
    });
  }, [selectedBots]);



  useEffect(() => {
    generateWebhookUrls();
  }, [selectedBots, generateWebhookUrls]);

  const handleSelectBot = (bot) => {
    setSelectedBots(prevSelectedBots =>
      prevSelectedBots.includes(bot.apiID)
        ? prevSelectedBots.filter(id => id !== bot.apiID)
        : [...prevSelectedBots, bot.apiID]
    );
  };

  const handleSortChange = (sort) => {
    setSortOrder(sort);
  };

  useEffect(() => {
    fetchBots();
  }, [sortOrder])

  const handleAction = async (bot, action) => {
    let apiUrl;
    let method = 'POST';
    let bodyData = {};

    if (action === 'delete') {
      apiUrl = `${config.apiBaseUrl}/api/bot/${bot.id}`;
      method = 'DELETE';
    } else if (action === 'start') {
      apiUrl = `${config.apiBaseUrl}/api/bot/${bot.id}/start`;
    } else if (action === 'stop') {
      apiUrl = `${config.apiBaseUrl}/api/bot/${bot.id}/stop`;      
    } else if (action === 'convert') {
      apiUrl = `${config.apiBaseUrl}/api/bot/${bot.id}/convert`;
    }
    

    try {
      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();
      if (data.status == 'error') {
        notifyError(`Action ${action} failed - ${data.errorText}`);
        for(var err of data.errors) {
          notifyError(`${err.field}: ${err.message}`);
        }
        return false;
      }

      fetchBots();
      return true;

    } catch (error) {
      notifyError(`Action ${action} failed - ${error}`);
      return false;
    }
  };

  const handleBulkAction = async (action) => {
    if (selectedBots.length === 0) return false;

    const ids = btoa(selectedBots.join(','));
    const url = `${config.apiBaseUrl}/api/botmassaction/${action}/${ids}`;

    try {
      const response = await fetch(url, { method: 'GET', });
      const data = await response.json();
      if (data.status == 'error') {
        notifyError(`Action ${action} failed - ${data.errorText}`);
        for(var err of data.errors) {
          notifyError(`${err.field}: ${err.message}`);
        }
        return false;
      }

      fetchBots();
      return true;

    } catch (error) {
      notifyError(`Action ${action} failed - ${error}`);
      return false;
    }
  };

  const handleConvertSelected = () => handleBulkAction('convert');
  const handleDeleteSelected = () => handleBulkAction('delete');
  const handleStartSelected = () => handleBulkAction('start');
  const handleStopSelected = () => handleBulkAction('stop');
  const handleSelectAll = () => {
    if (selectedBots.length === bots.length) {
      setSelectedBots([]);
    } else {
      setSelectedBots(bots.map(bot => bot.apiID));
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleBotsPerPageChange = (event) => {
    setBotsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchBots();   
  };

  const handleDirectionChange = (e) => {
    setSearchDirection(e.target.value);
  };

  useEffect(() => {
    fetchBots();
  }, []);  

  const indexOfLastBot = currentPage * botsPerPage;
  const indexOfFirstBot = indexOfLastBot - botsPerPage;
  const currentBots = bots.slice(indexOfFirstBot, indexOfLastBot);


  return (
    <div className="container-9 w-container">
      <section className="bot-list-section">
        <h2>Bot list</h2>
        <form method="get" className="bot-list-form" onSubmit={handleSearch} ref={searchFormRef}>
          <table>
            <thead>
              <tr>
                <th>Bot ID</th>
                <th>&gt; Amount</th>
                <th>All direction</th>
                <th>&gt; Active deals</th>
                <th>&gt; PnL</th>
                <th>Add date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type="text" name="id" placeholder="Bot ID" className="input-small" /></td>
                <td><input type="text" name="amount_min" placeholder="> Amount" className="input-small" /></td>
                <td>
                  <select name="direction" className="select-small" value={searchDirection} onChange={handleDirectionChange}>
                    <option value="all">All direction</option>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </td>
                <td><input type="text" name="activeDeals_min" placeholder="> Active deals" className="input-small" /></td>
                <td><input type="text" name="pnl_min" placeholder="> PnL" className="input-small" /></td>
              </tr>
              <tr>
                <td></td>
                <td><input type="text" name="amount_max" placeholder="< Amount" className="input-small" /></td>
                <td>
                  <select name="state" className="select-small">
                    {stateSummary.map((state, index) => (
                      <option key={state.state} value={state.state}>
                        {state.caption}
                      </option>
                    ))}
                  </select>
                </td>
                <td><input type="text" name="activeDeals_max" placeholder="< Active deals" className="input-small" /></td>
                <td><input type="text" name="pnl_max" placeholder="< PnL" className="input-small" /></td>
                <td>
                  <select name="expirationInterval" className="select-small select-wide">
                    <option value="">Expiration time</option>
                    <option value="24h">expiring &lt; 24 h</option>
                    <option value="1h">expiring &lt; 1 h</option>
                    <option value="6h">expiring &lt; 6 h</option>
                    <option value="12h">expiring &lt; 12 h</option>
                    <option value="3d">expiring &lt; 3 d</option>
                    <option value="7d">expiring &lt; 7 d</option>
                    <option value="14d">expiring &lt; 14 d</option>
                    <option value="30d">expiring &lt; 30 d</option>
                    <option value="30d_more">expiring &gt; 30 d</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td><input type="text" name="leverage" placeholder="Leverage" className="input-small" /></td>
                <td></td>
                <td><input type="text" name="completedDeals_min" placeholder="> Completed deals" className="input-small" /></td>
                <td><input type="text" name="upnl_min" placeholder="> uPnL" className="input-small" /></td>
                <td>
                  <select name="perPage" className="select-small" onChange={handleBotsPerPageChange}>
                    <option value="50">50 per page</option>
                    <option value="5">5</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="2000">2000</option>
                    <option value="0">Show all</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td><input type="text" name="completedDeals_max" placeholder="< Completed deals" className="input-small" /></td>
                <td><input type="text" name="upnl_max" placeholder="< uPnL" className="input-small" /></td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="5"><input type="text" name="searchText" placeholder="symbol or notes" className="input-wide" /></td>
                <td><button type="submit" className="button-primary-2 button-search">Search</button></td>
              </tr>
            </tbody>
          </table>
        </form>

        <div className="table-wrapper">
          <table className="bot-table">
            <thead>
              <tr className="table-header">
                <th><SortableColumn name="symbol" sort={sortOrder} onChange={handleSortChange} >Symbol</SortableColumn></th>
                <th><SortableColumn name="amount" sort={sortOrder} onChange={handleSortChange} >Amount</SortableColumn></th>
                <th><SortableColumn name="direction" sort={sortOrder} onChange={handleSortChange} >Direction</SortableColumn></th>
                <th>Active deals</th>
                <th>PNL</th>
                <th><SortableColumn name="created_at" sort={sortOrder} onChange={handleSortChange} >Add Date</SortableColumn></th>
              </tr>
              <tr className="table-header">
                <th>Bot ID</th>
                <th>Leverage</th>
                <th><SortableColumn name="state" sort={sortOrder} onChange={handleSortChange} >Status</SortableColumn></th>
                <th>Comp. deals</th>
                <th>uPnL</th>
                <th><SortableColumn name="valid_until" sort={sortOrder} onChange={handleSortChange} >Exp time</SortableColumn></th>
              </tr>
            </thead>
            <tbody className="table-content">
              {currentBots.map(bot => (
                <React.Fragment key={bot.id}>
                  <tr>
                    <td className="symbol">
                      <div>{bot.symbol}</div>
                      {bot.template
                        ? <div className="templateName">Template: {bot.templateName}</div>
                        : <div className="templateName">no template</div>
                      }                      
                    </td>
                    <td className="amount">{bot.amount}</td>
                    <td className="direction">
                      <div className={bot.direction === 'sell' ? 'direction-sell' : 'direction-buy'}>
                        {bot.direction}
                      </div>
                    </td>
                    <td className="activeDeals">{bot.template ? bot.template.maxActiveDeals : '-'}</td>
                    <td className="pnl">
                      {bot.template 
                        ? `${((bot.template.takeProfit / 100) * bot.amount).toFixed(2)}`
                        : '-'}
                    </td>
                    <td className="addDate">{bot.createdAt}</td>
                  </tr>
                  <tr>
                    <td className="botId">
                      &nbsp;&nbsp; &nbsp;<input type="checkbox" onChange={() => handleSelectBot(bot)} checked={selectedBots.includes(bot.apiID)} />
                      &nbsp;&nbsp;&nbsp;{bot.apiID}
                    </td>
                    <td className="leverage">{bot.leverage}</td>
                    <td className="status">
                      {
                          (() => {
                              if (bot.state === 'started') {
                                return (<div className="state_started">STARTED</div>);
                              } else if (bot.state === 'stopped') {
                                  return (<div className="state_stopped">STOPPED</div>);
                              } else if (bot.state === 'working') {
                                  return (<div className="state_working">WORKING</div>);
                              } else if (bot.state === 'deleted') {
                                  return (<div className="state_deleted">DELETED</div>);                                  
                              } else {
                                return bot.state;
                              }
                          })()  
                      }                     
                    </td>
                    <td className="completedDeals">{bot.template ? bot.template.safety_orders_max : '-'}</td>
                    <td className="uPnL">{bot.template ? bot.template.trailingDeviation : '-'}</td>
                    <td className="left">{bot.validUntil ? bot.validUntil : 'no expiration'} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan="1">
                      <Link className="btn-logs" to={`/logs/${bot.id}`}>Logs</Link>
                      {
                          (() => {
                              if (bot.state === 'stopped') {
                                return (<button className="btn-start" onClick={() => handleAction(bot, 'start')}>Start</button>);
                              } else if (bot.state === 'started') {
                                  return (<button className="btn-stop" onClick={() => handleAction(bot, 'stop')}>Stop</button>)
                              } else if (bot.state === 'working') {
                                  return (<button className="btn-convert" onClick={() => handleAction(bot, 'convert')}>Convert</button>);
                              } else {
                                return '';
                              }
                          })()  
                      } 
                    </td>
                    <td colSpan="5" className="delete-cell">
                      {
                          (() => {
                            if (bot.state == 'started' || bot.state == 'stopped') {
                                return (<button className="btn-delete" onClick={() => handleAction(bot, 'delete')}>Delete</button>);
                              } else {
                                return '';
                              }
                          })() 
                      }
                    </td>
                  </tr>
                  <tr className="notes-row">
                    <td colSpan="6" className="notes">
                      {bot.notes}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {Array.from({ length: Math.ceil(bots.length / botsPerPage) }, (_, index) => (
            <button 
              key={index + 1} 
              onClick={() => handlePageChange(index + 1)} 
              className={index + 1 === currentPage ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div className="button-group">
          <button onClick={handleStartSelected} className="button-primary-2" disabled={selectedBots.length === 0}>Start Selected</button>
          <button onClick={handleStopSelected} className="button-primary-2" disabled={selectedBots.length === 0}>Stop Selected</button>
          <button onClick={handleDeleteSelected} className="button-primary-2" disabled={selectedBots.length === 0}>Delete Selected</button>
          <button onClick={handleConvertSelected} className="button-primary-2" disabled={selectedBots.length === 0}>Convert Selected</button>
          <button onClick={handleSelectAll} className="button-primary-2">Select All</button>
        </div>
        <div className="webhook-container">
          <h3>Generated Webhook URLs:</h3>
          <div className="webhook-field">
            <label>Start:</label>
            <input type="text" value={webhookUrls.start} onClick={() => {navigator.clipboard.writeText(webhookUrls.start)}} readOnly />
          </div>
          <div className="webhook-field">
            <label>Stop:</label>
            <input type="text" value={webhookUrls.stop} onClick={() => {navigator.clipboard.writeText(webhookUrls.stop)}} readOnly />
          </div>
          <div className="webhook-field">
            <label>Delete:</label>
            <input type="text" value={webhookUrls.delete} onClick={() => {navigator.clipboard.writeText(webhookUrls.delete)}} readOnly />
          </div>
          <div className="webhook-field">
            <label>Convert:</label>
            <input type="text" value={webhookUrls.convert} onClick={() => {navigator.clipboard.writeText(webhookUrls.convert)}} readOnly />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BotList;
