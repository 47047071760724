import React, { useState, useEffect } from 'react';
import {notifyError, notifySuccess} from './notifications';
import './css/BotTemplate.css'; // Ensure this path is correct
import config from '../config/config';

const BotTemplate = () => {
  const [formData, setFormData] = useState({
    content: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/bottemplate/json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: formData.content,
      });


      const data = await response.json();
      if (data.status == 'error') {
        notifyError(`Saving templates failed - ${data.errorText}`);
        for(var err of data.errors) {
          notifyError(`${err.field}: ${err.message}`);
        }        
        return false;
      }
      
      notifySuccess('Templates saved');
      return true;
    } catch (error) {
      notifyError(`Saving templates failed - ${error}`);
      return false;
    }
  };

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/api/bottemplate/json`)
      .then(response => response.json())
      .then(data => {
        setFormData({
          content: data.content
        })
      })
      .catch(error => console.error('Error fetching templates:', error));
  }, []);  


  return (
    <div className="bot-template-container">
      <form className="template-form-flex" onSubmit={handleSubmit}>
        <h2>Templates</h2>

        <div>
          <textarea
            name="content"
            value={formData.content}
            onChange={handleChange}
          ></textarea>          
        </div>

        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default BotTemplate;
