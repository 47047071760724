import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import {notifyError, notifySuccess} from './notifications';
import SortableColumn from './SortableColumn';
import config from '../config/config';  // Adjust the path if necessary
import './css/BotLogs.css';
import '../App.css'; // Import styles

const BotLogs = () => {
  const [logs, setLogs] = useState([]);
  const { botID } = useParams();
  const searchFormRef = React.createRef();
  const [sortOrder, setSortOrder] = useState('');


  const fetchLogs = useCallback(() => {
    const formData = new FormData(searchFormRef.current);
    const searchParams = new URLSearchParams();

    formData.forEach((value, key) => {
      if (value) {
        searchParams.append(key, value);
      }
    });

    if (sortOrder) {
      searchParams.append('sort', sortOrder);
    }    

    fetch(`${config.apiBaseUrl}/api/log?${searchParams.toString()}`)
      .then(response => response.json())
      .then(data => {
        setLogs(Array.isArray(data.logs) ? data.logs : []);
      })
      .catch(error => notifyError(`Error loading logs: ${error}`));
  }, [searchFormRef, sortOrder]);

  const handleSearch = (event) => {
    event.preventDefault();
    fetchLogs();
  };

  const handleSortChange = (sort) => {
    setSortOrder(sort);
  };  

  useEffect(() => {
    fetchLogs();
  }, [ ]);
  useEffect(() => {
    fetchLogs();
  }, [ sortOrder ]);




  return (
    <div className="container-9 w-container">
      <section className="bot-logs-section">
        <h2>Logs</h2>
        <form method="get" className="bot-logs-form" ref={searchFormRef} onSubmit={handleSearch}>
          <table>
            <thead>
              <tr>
                <th>Bot ID</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type="text" name="bot_id" placeholder="Bot ID" className="input-small" value={botID} /></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><button type="submit" className="button-primary-2 button-search">Search</button></td>
              </tr>
            </tbody>
          </table>        
        </form>    
        <div className="table-wrapper">
          <table class="logs-table">
            <thead>
              <tr>
                <th><SortableColumn name="date" sort={sortOrder} onChange={handleSortChange} >Time</SortableColumn></th>
                <th><SortableColumn name="severity" sort={sortOrder} onChange={handleSortChange} >Severity</SortableColumn></th>
                <th><SortableColumn name="bot_id" sort={sortOrder} onChange={handleSortChange} >Bot</SortableColumn></th>
                <th><SortableColumn name="symbol" sort={sortOrder} onChange={handleSortChange} >Symbol</SortableColumn></th>
                <th>Direction</th>
                <th>Template</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{log.time}</td>
                  <td>
                    {
                        (() => {
                            if (log.severity === 1) {
                              return 'INFO';
                            } else if (log.severity == 2) {
                                return 'WARNING';
                            } else if (log.severity == 3) {
                                return 'ERROR';
                            } else {
                              return 'UNKNOWN';
                            }
                        })()  
                    } 
                  </td>
                  <td>{log.bot_api_id}</td>
                  <td>{log.bot_symbol}</td>
                  <td>
                      <div className={log.bot_direction === 'sell' ? 'direction-sell' : 'direction-buy'}>
                        {log.bot_direction}
                      </div>
                  </td>
                  <td>{log.bot_template}</td>
                  <td>{log.content}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default BotLogs;
