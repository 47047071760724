import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import AddBot from './components/AddBot';
import BotList from './components/BotList';
import BotLogs from './components/BotLogs';
import BotTemplate from './components/BotTemplate';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/add" element={<AddBot />} />
          <Route path="/list" element={<BotList />} />
          <Route path="/logs/:botID?" element={<BotLogs />} />
          <Route path="/" element={<BotList />} /> 
          <Route path="/templates" element={<BotTemplate />} />
        </Routes>
        <NotificationContainer/>
      </div>
    </Router>
  );
}

export default App;
