import React, { useState, useEffect } from 'react';
import {notifyError, notifySuccess} from './notifications';
import './css/BotList.css';
import config from '../config/config';

const AddBot = () => {
  const [formData, setFormData] = useState({
    accountID: 0,
    symbol: '',
    direction: 'buy',
    leverage: 1,
    amount: 0,
    minPrice: 0,
    maxPrice: 0,
    notes: '',
    expirationInterval: '',
    startAfterAdd: true,
    templateName: '',
  });

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/api/bottemplate`)
      .then(response => response.json())
      .then(data => {
        console.log('Templates data:', data);
        if (Array.isArray(data.templates)) {
          setTemplates(data.templates);
        } else {
          console.error('Error fetching templates: Invalid response format');
        }
      })
      .catch(error => console.error('Error fetching templates:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form data:', formData); // Debugging log
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/bot/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data.status == 'error') {
        notifyError(`Creating bot failed - ${data.errorText}`);
        for(var err of data.errors) {
          notifyError(`${err.field}: ${err.message}`);
        }        
        return false;
      }
      
      notifySuccess('Created bot ' + data.bot.id);

    } catch (error) {
      notifyError(`Creating bot failed - ${error}`);
      return false;
    }
  };

  return (
    <div className="add-bot-container">
      <form className="subscribe-form-flex" onSubmit={handleSubmit}>
        <h2>New Bot</h2>
        <div className="form-group">
          <label>AccountID:</label>
          <input
            type="text"
            name="accountID"
            value={formData.accountID}
            onChange={handleChange}
            required
          />
        </div>        
        <div className="form-group">
          <label>Symbol:</label>
          <input
            type="text"
            name="symbol"
            value={formData.symbol}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Direction:</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="direction"
                value="buy"
                checked={formData.direction === 'buy'}
                onChange={handleChange}
              />
              Buy
            </label>
            <label>
              <input
                type="radio"
                name="direction"
                value="sell"
                checked={formData.direction === 'sell'}
                onChange={handleChange}
              />
              Sell
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Leverage:</label>
          <div className="radio-group-horizontal">
            {[1, 3, 5, 10, 25, 50].map((value) => (
              <label key={value}>
                <input
                  type="radio"
                  name="leverage"
                  value={value}
                  checked={formData.leverage == value}
                  onChange={handleChange}
                />
                {value}
              </label>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label>Amount:</label>
          <input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Min price to open:</label>
          <input
            type="number"
            name="minPrice"
            value={formData.minPrice}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Max price to open:</label>
          <input
            type="number"
            name="maxPrice"
            value={formData.maxPrice}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Notes:</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Expiration time:</label>
          <select
            name="expirationInterval"
            value={formData.expirationInterval}
            onChange={handleChange}
          >
            <option value="">None</option>
            <option value="1m">1 minute</option>
            <option value="5m">5 minutes</option>
            <option value="15m">15 minutes</option>
            <option value="30m">30 minutes</option>
            <option value="1h">1 hour</option>
            <option value="4h">4 hours</option>
            <option value="8h">8 hours</option>
            <option value="12h">12 hours</option>
            <option value="1d">1 day</option>
            <option value="3d">3 days</option>
            <option value="7d">7 days</option>
            <option value="14d">14 days</option>
            <option value="30d">30 days</option>
            <option value="end_of_day">End of day</option>
            <option value="end_of_week">End of week</option>
            <option value="end_of_month">End of month</option>
          </select>
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              name="startAfterAdd"
              checked={formData.startAfterAdd}
              onChange={handleChange}
            />
            Start bot after add
          </label>
        </div>
        <div className="form-group">
          <label>Template:</label>
          <select
            name="templateName"
            value={formData.templateName}
            onChange={handleChange}
          >
            <option value="">Select a template</option>
            {templates.map((template, index) => (
              <option key={index} value={template.name}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddBot;
