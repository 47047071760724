import React from 'react';
import { Link } from 'react-router-dom';


import '../App.css'; // Import stylów

function Navbar() {
  return (
    <div className="navbar">
      <Link to="/add" className="nav-link">Add new</Link>
      <Link to="/list" className="nav-link">List</Link>
      <Link to="/templates" className="nav-link">Templates</Link>
      <Link to="/logs" className="nav-link">Logs</Link>
    </div>
  );
}

export default Navbar;
